
import { defineComponent, ref, computed, onMounted, Ref } from 'vue';
import { injectRouter } from '@/router';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { Language, Test, ApplicantTests } from '@/interfaces/interfaces';
import { injectApiClient } from '@/services/apiClient';
import { getLangName, getLangOwnName } from '@/services/langNames';
import Checkmark from '@/components/common/Checkmark.vue';

export default defineComponent({
  name: 'ApplicantSelectTest',
  components: { Checkmark },
  setup() {
    const i18n = useI18n();
    const i18nGlobal = useI18n({ useScope: 'global' });
    const router = injectRouter();
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const altusSuiteUrl = process.env.VUE_APP_ALTUS_SUITE_URL;

    const tests = ref<ApplicantTests | null>(null);
    const selectedTest = ref<Test | null>(null);
    const selectedLanguage = i18nGlobal.locale as Ref<Language>;

    const languages = computed(() => {
      if (tests.value === null) {
        return [];
      }
      const result: Language[] = [];
      for (const testsInCategory of Object.values(tests.value)) {
        for (const test of testsInCategory) {
          if (!result.includes(test.lang)) {
            result.push(test.lang);
          }
        }
      }
      return result;
    });

    const testsPending = computed(
      () =>
        tests.value?.pending.filter(test => test.lang === selectedLanguage.value) ?? []
    );

    const testsCompleted = computed(
      () =>
        tests.value?.completed.filter(test => test.lang === selectedLanguage.value) ?? []
    );

    const hasAnyTests = computed(
      () => testsPending.value.length > 0 || testsCompleted.value.length > 0
    );

    async function selectTest() {
      if (selectedTest.value !== null) {
        applicant.selectTest(selectedTest.value);
        await router.push({ name: 'applicant.home' });
      }
    }

    function selectLanguage(lang: Language) {
      selectedTest.value = null;
      selectedLanguage.value = lang;
    }

    onMounted(async () => {
      tests.value = await client.getApplicantTests();
      if (!languages.value.includes(selectedLanguage.value)) {
        selectedLanguage.value = languages.value[0];
      }
    });

    return {
      tests,
      languages,
      selectedLanguage,
      selectLanguage,
      testsPending,
      testsCompleted,
      selectedTest,
      selectTest,
      altusSuiteUrl,
      getLangName,
      getLangOwnName,
      t: i18n.t,
      hasAnyTests
    };
  }
});
