<template>
  <div v-if="tests !== null">
    <a-header dotted class="h-64" />
    <div
      class="w-11/12 md:w-4/5 xl:w-4/6 -mt-44 px-3 mx-auto border border-gray-lighter rounded-lg bg-white shadow"
    >
      <ul
        v-if="languages.length > 1"
        class="w-full pt-3 border-b border-gray-lighter text-center"
      >
        <li class="inline-block">
          <button
            v-if="languages.includes('en')"
            class="tab-button"
            data-cy="button-test-lang-select"
            :class="{ selected: selectedLanguage === 'en' }"
            @click="selectLanguage('en')"
          >
            {{ getLangOwnName('en') }}
          </button>
        </li>
        <li class="inline-block">
          <button
            v-if="languages.includes('fr')"
            class="tab-button ml-12"
            data-cy="button-test-lang-select"
            :class="{ selected: selectedLanguage === 'fr' }"
            @click="selectLanguage('fr')"
          >
            {{ getLangOwnName('fr') }}
          </button>
        </li>
      </ul>
      <main class="flex py-14 px-12">
        <div class="flex-none">
          <img src="@/assets/images/snapshot-logo-circle.svg" class="w-26" alt="" />
        </div>
        <form class="ml-10 flex-grow" @submit.prevent="selectTest()">
          <div class="pr-14">
            <h1 class="mt-7 font-bold text-3xl text-blue-darkest">
              {{ t('welcomeSnapshotTitle') }}
            </h1>
            <div>
              <div v-if="hasAnyTests">
                <div class="prose mt-10 mb-4" v-html="t('welcomeDescription')" />
                <p v-if="testsPending.length > 0" id="radio-group-select-test">
                  {{ t('selectTest') }}
                </p>
                <div
                  class="mt-4 mb-4"
                  role="radiogroup"
                  aria-labelledby="radio-group-select-test"
                >
                  <div v-for="test in testsPending" :key="test.id" class="mb-2">
                    <a-radio-button
                      name="test"
                      :value="test"
                      v-model="selectedTest"
                      data-cy="radio-test-select"
                    >
                      <span v-if="test.cycle"> {{ test.cycle.name }} &mdash; </span>
                      <b>{{ test.name }}</b>
                      {{ getLangName(test.lang) }}
                    </a-radio-button>
                  </div>
                </div>
              </div>
              <p v-else class="mt-10 mb-10" data-cy="txt-no-tests-available">
                {{ t('noTestsAvailable') }}
              </p>
            </div>
            <div v-if="testsCompleted.length > 0" class="mt-4">
              <h2 class="text-sm text-gray-darker font-bold">
                {{ t('successfullyCompleted') }}
              </h2>
              <ul class="mt-2">
                <li v-for="test in testsCompleted" :key="test.id" class="mb-2 flex">
                  <div
                    class="w-4 h-4 flex-none flex justify-center items-center rounded-full border border-green-dark"
                  >
                    <Checkmark class="text-green-dark w-2" />
                  </div>
                  <span class="ml-2 text-sm text-gray-dark" data-cy="txt-test-completed">
                    <span v-if="test.cycle">{{ test.cycle.name }} &mdash; </span>
                    <b>{{ test.name }}</b>
                    {{ getLangName(test.lang) }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-12">
            <a-button
              link
              :href="altusSuiteUrl"
              v-if="testsPending.length === 0"
              data-cy="button-back-to-altus-suite"
            >
              <a-arrow class="inline-block w-4 mr-1.5 -mt-1" />
              {{ t('backToMyAssessments') }}
            </a-button>
            <a-button
              v-else
              type="submit"
              class="text-center float-right"
              :disabled="selectedTest === null"
            >
              {{ t('next') }}
              <a-arrow class="inline-block w-4 ml-1.5 -mt-1 transform rotate-180" />
            </a-button>
          </div>
        </form>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, Ref } from 'vue';
import { injectRouter } from '@/router';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { Language, Test, ApplicantTests } from '@/interfaces/interfaces';
import { injectApiClient } from '@/services/apiClient';
import { getLangName, getLangOwnName } from '@/services/langNames';
import Checkmark from '@/components/common/Checkmark.vue';

export default defineComponent({
  name: 'ApplicantSelectTest',
  components: { Checkmark },
  setup() {
    const i18n = useI18n();
    const i18nGlobal = useI18n({ useScope: 'global' });
    const router = injectRouter();
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const altusSuiteUrl = process.env.VUE_APP_ALTUS_SUITE_URL;

    const tests = ref<ApplicantTests | null>(null);
    const selectedTest = ref<Test | null>(null);
    const selectedLanguage = i18nGlobal.locale as Ref<Language>;

    const languages = computed(() => {
      if (tests.value === null) {
        return [];
      }
      const result: Language[] = [];
      for (const testsInCategory of Object.values(tests.value)) {
        for (const test of testsInCategory) {
          if (!result.includes(test.lang)) {
            result.push(test.lang);
          }
        }
      }
      return result;
    });

    const testsPending = computed(
      () =>
        tests.value?.pending.filter(test => test.lang === selectedLanguage.value) ?? []
    );

    const testsCompleted = computed(
      () =>
        tests.value?.completed.filter(test => test.lang === selectedLanguage.value) ?? []
    );

    const hasAnyTests = computed(
      () => testsPending.value.length > 0 || testsCompleted.value.length > 0
    );

    async function selectTest() {
      if (selectedTest.value !== null) {
        applicant.selectTest(selectedTest.value);
        await router.push({ name: 'applicant.home' });
      }
    }

    function selectLanguage(lang: Language) {
      selectedTest.value = null;
      selectedLanguage.value = lang;
    }

    onMounted(async () => {
      tests.value = await client.getApplicantTests();
      if (!languages.value.includes(selectedLanguage.value)) {
        selectedLanguage.value = languages.value[0];
      }
    });

    return {
      tests,
      languages,
      selectedLanguage,
      selectLanguage,
      testsPending,
      testsCompleted,
      selectedTest,
      selectTest,
      altusSuiteUrl,
      getLangName,
      getLangOwnName,
      t: i18n.t,
      hasAnyTests
    };
  }
});
</script>

<style lang="postcss" scoped>
.tab-button {
  @apply py-4 text-gray-dark text-base font-bold transition;

  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }

  &.selected {
    @apply outline-none text-blue border-b-4 border-blue;
  }
}
</style>

<i18n>
{
  "en": {
    "backToMyAssessments": "Back to my assessments",
    "next": "Next",
    "noTestsAvailable": "You don't have Snapshot tests available.",
    "selectTest": "Please select the admissions cycle you are applying to below:",
    "successfullyCompleted": "Successfully Completed",
    "welcomeDescription": "<p><strong>You must complete Snapshot in the language for which you are completing Casper</strong> (i.e., if you completed Casper in English, please complete Snapshot in English).</p><p><strong>Important:</strong> Before you start, check your Casper reservation(s) and the language(s). If you are completing Casper in both English and French, <strong>you must return to this page to complete Snapshot again in each language.</strong> Programs will only have access to your responses in the relevant language.</p><p><strong>Your Snapshot responses (in each language) will be sent to all programs on your distribution list(s) regardless of program type or location.</strong></p>",
    "welcomeSnapshotTitle": "Welcome to Snapshot"
  },
  "fr": {
    "backToMyAssessments": "Retourner à mes évaluations",
    "next": "Suivante",
    "noTestsAvailable": "Vous n'avez pas d'entrevue Snapshot disponible.",
    "selectTest": "Veuillez sélectionner ci-dessous le cycle d'admission auquel vous postulez:",
    "successfullyCompleted": "Achevé avec succès",
    "welcomeDescription": "<p><strong>Vous devez compléter Snapshot dans la langue pour laquelle vous complétez Casper</strong> (c'est-à-dire que si vous avez complété Casper en anglais, veuillez compléter Snapshot en anglais).</p><p><strong>Important:</strong> avant de commencer, vérifiez votre (vos) réservation(s) Casper et la (les) langue(s). Si vous remplissez Casper en anglais et en français, <strong>vous devez revenir à cette page pour remplir à nouveau l'instantané dans chaque langue.</strong> Les programmes n'auront accès à vos réponses que dans la langue concernée.</p><p><strong>Vos réponses Snapshot (dans chaque langue) seront envoyées à tous les programmes sur votre liste de distribution, peu importe la catégorie du programme ou l'emplacement de l'établissement.</strong></p>",
    "welcomeSnapshotTitle": "Votre entrevue Snapshot"
  }
}
</i18n>
