export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToMyAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my assessments"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "noTestsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have Snapshot tests available."])},
        "selectTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the admissions cycle you are applying to below:"])},
        "successfullyCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully Completed"])},
        "welcomeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><strong>You must complete Snapshot in the language for which you are completing Casper</strong> (i.e., if you completed Casper in English, please complete Snapshot in English).</p><p><strong>Important:</strong> Before you start, check your Casper reservation(s) and the language(s). If you are completing Casper in both English and French, <strong>you must return to this page to complete Snapshot again in each language.</strong> Programs will only have access to your responses in the relevant language.</p><p><strong>Your Snapshot responses (in each language) will be sent to all programs on your distribution list(s) regardless of program type or location.</strong></p>"])},
        "welcomeSnapshotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Snapshot"])}
      },
      "fr": {
        "backToMyAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mes évaluations"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivante"])},
        "noTestsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas d'entrevue Snapshot disponible."])},
        "selectTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner ci-dessous le cycle d'admission auquel vous postulez:"])},
        "successfullyCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achevé avec succès"])},
        "welcomeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p><strong>Vous devez compléter Snapshot dans la langue pour laquelle vous complétez Casper</strong> (c'est-à-dire que si vous avez complété Casper en anglais, veuillez compléter Snapshot en anglais).</p><p><strong>Important:</strong> avant de commencer, vérifiez votre (vos) réservation(s) Casper et la (les) langue(s). Si vous remplissez Casper en anglais et en français, <strong>vous devez revenir à cette page pour remplir à nouveau l'instantané dans chaque langue.</strong> Les programmes n'auront accès à vos réponses que dans la langue concernée.</p><p><strong>Vos réponses Snapshot (dans chaque langue) seront envoyées à tous les programmes sur votre liste de distribution, peu importe la catégorie du programme ou l'emplacement de l'établissement.</strong></p>"])},
        "welcomeSnapshotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entrevue Snapshot"])}
      }
    }
  })
}
